import React, {Component} from 'react';
import {Chart as ChartJS, registerables} from 'chart.js';
import {useNavigate} from 'react-router-dom';
import {
    Box,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField
} from '@mui/material';
import server from "../services/server";
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import {IColumnArray, IColumnObject} from "../services/types/columns";
import AlignDir from "../services/languages/AlignDir";
import {CHARTS, TChartType, THideEmptyData} from "./charts/chartComponents";
import {incidentStatus, incidentStatusOptions} from "../services/types/incidentStatus";

ChartJS.register(...registerables);

interface IProps {
}

interface IState {
    chartData: [] | null,
    loadingData: boolean,
    columnSchema: IColumnObject | null,
    loadingColumns: boolean,
    selectedField: string | null,
    selectedSecondaryField: string | null,
    chartType?: TChartType,
    filterStatus: string | null,
    filterStartDate: string | null,
    filterEndDate: string | null,
    hideEmptyData: THideEmptyData,
}

class Charts extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            chartData: [],
            loadingData: false,
            columnSchema: null,
            loadingColumns: false,
            selectedField: null,
            selectedSecondaryField: null,
            chartType: undefined,
            filterStatus: null,
            filterStartDate: null,
            filterEndDate: null,
            hideEmptyData: "SHOW"
        };
    }

    formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    };

    fetchData = async () => {
        const {selectedField, selectedSecondaryField, filterStartDate, filterEndDate} = this.state;
        if (this.state.loadingData || selectedField === null) {
            return
        }
        this.setState({loadingData: true}, async () => {
            const requestBody: { [key: string]: string | null } = {
                primary_var: selectedField,
                secondary_var: selectedSecondaryField,
                start_date: !filterStartDate ? null : this.formatDate(new Date(filterStartDate)),
                end_date: !filterEndDate ? null : this.formatDate(new Date(filterEndDate)),
                incident_status: this.state.filterStatus
            };
            const res = await server.post("insights/charts/", requestBody);
            this.setState({chartData: res, loadingData: false});
        })
    };

    fetchColumns = async () => {
        if (this.state.loadingColumns) {
            return
        }
        this.setState({loadingColumns: true}, async () => {
            const res = await server.get("columns/");
            if (res && !res.error) {
                const columnSchema = (((res as IColumnObject).properties[0] as IColumnArray).entries as IColumnObject);
                const selectedField = columnSchema.properties
                    .filter((p: any) => !(["ARRAY", "OBJECT", "TEXT", "CITY"].includes(p.column_type)))[0]
                    .key || null
                this.setState({columnSchema, selectedField, loadingColumns: false}, async () => {
                    await this.fetchData();
                });
            }
        })
    };

    async componentDidMount() {
        await this.fetchColumns();
    }

    async componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.selectedField !== this.state.selectedField) {
            if (!this.state.selectedField) {
                return
            }
            const supported = this.getSupportedChartTypesForColumns(this.state.selectedField)
            const currentChartType = this.state.chartType;
            if (currentChartType && supported.includes(currentChartType)) {
                await this.fetchData();
            } else {
                this.setState({chartType: supported[0] || undefined})
            }
            return;
        }
        if (
            prevState.filterStartDate !== this.state.filterStartDate ||
            prevState.filterEndDate !== this.state.filterEndDate ||
            prevState.filterStatus !== this.state.filterStatus ||
            prevState.selectedField !== this.state.selectedField ||
            prevState.selectedSecondaryField !== this.state.selectedSecondaryField ||
            prevState.chartType !== this.state.chartType
        ) {
            await this.fetchData();
        }
    }

    getSupportedChartTypesForColumns = (columnKey: string): TChartType[] => {
        const columnSchema = this.state.columnSchema;
        const matchingFields = columnSchema?.properties
            .filter(f => f.key === columnKey)
        if (!matchingFields || !matchingFields.length) {
            return []
        }
        const selectedField = matchingFields[0];
        return Object.entries(CHARTS)
            .filter(ct => ct[1].mainVariables.includes(selectedField.column_type))
            .map(ct => ct[0] as TChartType)
    }

    handleChartTypeChange = (event: SelectChangeEvent) => {
        let selectedSecondaryField = this.state.selectedSecondaryField;
        const chartType = event.target.value as TChartType;
        const newChartType = CHARTS[chartType]
        if (selectedSecondaryField) {
            const columnSchema = this.state.columnSchema;
            const matchingFields = columnSchema?.properties
                .filter(f => f.key === selectedSecondaryField)
            if (!matchingFields || !matchingFields.length) {
                selectedSecondaryField = null
            } else {
                const selectedSecondaryFieldSchema = matchingFields[0];
                if (!newChartType.secondaryVariables || !newChartType.secondaryVariables.includes(selectedSecondaryFieldSchema.column_type)) {
                    selectedSecondaryField = null
                }
            }
        }
        this.setState({
            chartType,
            selectedSecondaryField
        });
    };

    handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({filterStartDate: e.target.value});
    };

    handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({filterEndDate: e.target.value});
    };

    render() {
        const {
            chartData,
            selectedField,
            selectedSecondaryField,
            chartType,
            filterStartDate,
            filterEndDate,
            columnSchema,
            hideEmptyData
        } = this.state;
        const availableChartTypes = selectedField ? this.getSupportedChartTypesForColumns(selectedField) : [];
        const selectedChart = chartType ? CHARTS[chartType] : null;
        const selectedFieldSchema = columnSchema?.properties
            .filter(f => f.key === selectedField)[0]
        return (
            <div className={"page-wrap"}>
                <TopNavBar>
                    <div className="title-wrap">
                        Charts
                    </div>
                </TopNavBar>
                <div className={"page-content content-wrap"}>
                    <AlignDir direction={"ltr"}>
                        <h2>Select a variable and a chart type</h2>
                        <Stack direction={"row"} gap={1}>
                            <Stack direction={"column"} gap={1} sx={{width: "50%"}}>
                                <FormControl fullWidth>
                                    <InputLabel>Variable</InputLabel>
                                    <Select
                                        value={selectedField || ""}
                                        onChange={(e) => this.setState({selectedField: e.target.value as string})}
                                        fullWidth
                                        label={"Variable"}
                                    >
                                        {columnSchema?.properties
                                            .filter((p: any) => !(["ARRAY", "OBJECT", "TEXT", "CITY"].includes(p.column_type)))
                                            .map((p: any) => (
                                                <MenuItem key={p.key} value={p.key}>{p.title}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                                {
                                    selectedChart && selectedChart.secondaryVariables ? <FormControl fullWidth>
                                            <InputLabel>Secondary Variable (Color)</InputLabel>
                                            <Select
                                                value={selectedSecondaryField || ""}
                                                onChange={(e) => this.setState({selectedSecondaryField: e.target.value as string})}
                                                fullWidth
                                                label={"Secondary Variable (Color)"}
                                            >
                                                {columnSchema?.properties
                                                    .filter((p: any) => ((selectedChart?.secondaryVariables || []).includes(p.column_type)))
                                                    .map((p: any) => (
                                                        <MenuItem key={p.key} value={p.key}>{p.title}</MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                        : null
                                }
                            </Stack>
                            <FormControl fullWidth sx={{width: "50%"}}>
                                <InputLabel>Chart Type</InputLabel>
                                <Select value={chartType || ""}
                                        onChange={this.handleChartTypeChange}
                                        fullWidth
                                        label={"Chart Type"}>
                                    <MenuItem value=""></MenuItem>
                                    {
                                        Object.entries(CHARTS)
                                            .filter(ct => availableChartTypes.includes(ct[0] as TChartType))
                                            .map(ct => {
                                                return <MenuItem value={ct[0]} key={ct[0]}>
                                                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                                                        {ct[1].icon}
                                                        <span>{ct[1].title}</span>
                                                    </Stack>
                                                </MenuItem>
                                            })
                                    }
                                </Select>
                            </FormControl>
                        </Stack>
                        <h2>Filter Incidents</h2>
                        <Stack direction={"row"} gap={1}>
                            <Stack direction={"row"} gap={1}>
                                <TextField
                                    type="date"
                                    label="Start Date"
                                    value={filterStartDate}
                                    onChange={this.handleStartDateChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    type="date"
                                    label="End Date"
                                    value={filterEndDate}
                                    onChange={this.handleEndDateChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Stack>
                            <FormControl>
                                <InputLabel>Incident Status</InputLabel>
                                <Select
                                    label={"Incident Status"}
                                    fullWidth
                                    sx={{width: "200px"}}
                                    value={this.state.filterStatus || ""}
                                    onChange={async (e) => {
                                        const value = e.target.value;
                                        this.setState({filterStatus: value.length ? value : null})
                                    }}
                                >
                                    <MenuItem value={""}>any status</MenuItem>
                                    {
                                        incidentStatusOptions.map((s) => {
                                            return <MenuItem key={s} value={s}>{s}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl>
                                <InputLabel>Empty Data</InputLabel>
                                <Select
                                    label={"Empty Data"}
                                    fullWidth
                                    sx={{width: "200px"}}
                                    value={this.state.hideEmptyData}
                                    onChange={async (e) => {
                                        this.setState({hideEmptyData: e.target.value as THideEmptyData})
                                    }}
                                >
                                    <MenuItem value={"SHOW"}>Show</MenuItem>
                                    <MenuItem value={"HIDE"}>Hide</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <h2>#Incidents per {selectedFieldSchema?.title || ""}</h2>
                        <Box sx={{flexGrow: 1}} justifyContent={"center"}>
                            {chartData && selectedChart ? selectedChart.renderer({data: chartData, hideEmptyData: hideEmptyData}) : <CircularProgress/>}
                        </Box>
                    </AlignDir>
                </div>
            </div>
        );
    }
}

const ChartsWithNavigate = (props: any) => {
    const navigate = useNavigate();
    return <Charts {...props} navigate={navigate}/>;
};

export default ChartsWithNavigate;