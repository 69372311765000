import React, {Component} from "react";
import {Box} from "@mui/material";
import {Bar, Line, Pie} from "react-chartjs-2";
import {TColumnTypes} from "../../services/types/columns";
import {BarChart as BarChartIcon, PieChart as PieChartIcon, Timeline as LineChartIcon} from "@mui/icons-material";


interface IChartDataRow {
    primary_variable: string | null,
    secondary_variable: string | null,
    count: number
}

interface IChartsJSChartWrapProps {
    data: IChartDataRow[]
    hideEmptyData: THideEmptyData
}

interface IChartsJSChartWrapState {
    data: ITreatedData
}

interface ITreatedData {
    labels: string[];
    datasets: { label: string; data: number[]; borderWidth: number; }[];
}

const NO_DATA_SUBSTITUTE = "no data"
class ChartsJSChartWrap extends Component<IChartsJSChartWrapProps, IChartsJSChartWrapState> {
    constructor(props: IChartsJSChartWrapProps) {
        super(props);
        this.state = {
            data: this.treatData(props.data, props.hideEmptyData)
        }
    }

    componentDidUpdate(prevProps: Readonly<IChartsJSChartWrapProps>, prevState: Readonly<IChartsJSChartWrapState>, snapshot?: any) {
        if(
            this.props.hideEmptyData !== prevProps.hideEmptyData ||
            this.props.data !== prevProps.data
        ){
            this.setState({
                data: this.treatData(this.props.data, this.props.hideEmptyData)
            })
        }
    }

    treatData = (data: IChartDataRow[], hideEmptyData: THideEmptyData) => {
        const safeData = Array.isArray(data) ? data : [];

        const primaryLabels = Array.from(new Set(safeData.map((x: any) => x.primary_variable || NO_DATA_SUBSTITUTE)))
            .filter(x => x !== NO_DATA_SUBSTITUTE || hideEmptyData === "SHOW")
        const secondaryLabels = Array.from(new Set(safeData.map((x: any) => x.secondary_variable || NO_DATA_SUBSTITUTE)))
            .filter(x => x !== NO_DATA_SUBSTITUTE || hideEmptyData === "SHOW")


        return {
            labels: primaryLabels,
            datasets:
                secondaryLabels.map(s => {
                    return {
                        label: s,
                        data: primaryLabels.map(p => {
                            return safeData
                                .filter(x =>
                                    ((x.primary_variable || NO_DATA_SUBSTITUTE) === p) &&
                                    ((x.secondary_variable || NO_DATA_SUBSTITUTE) === s)
                                )
                                .map(x => x.count)
                                .reduce((partialSum, a) => partialSum + a, 0)
                        }),
                        borderWidth: 1,
                    }
                })
        };
    }
}

class LineChart extends ChartsJSChartWrap {
    render() {
        const options = {
            responsive: true,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context: any) {
                            return `${context.dataset.label}: ${context.raw}`;
                        }
                    }
                },
                legend: {
                    display: true,
                    position: 'top' as const,
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    }
                },
                y: {
                    beginAtZero: true,
                    grid: {
                        color: 'rgba(200, 200, 200, 0.2)'
                    }
                }
            },
            elements: {
                line: {
                    tension: 0.4
                }
            }
        };

        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                maxHeight: '70vh',
                overflowY: 'auto',
                width: "100%"
            }} justifyContent={"center"}>
                <Line data={this.state.data} options={options}/>
            </Box>
        );
    }
}

class BarChart extends ChartsJSChartWrap {
    render() {
        const options = {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'top' as const,
                },
                tooltip: {
                    callbacks: {
                        label: function (context: any) {
                            return `${context.dataset.label}: ${context.raw}`;
                        },
                    }
                }
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    stacked: true,
                },
                y: {
                    beginAtZero: true,
                    grid: {
                        color: 'rgba(200, 200, 200, 0.2)'
                    },
                    stacked: true,
                }
            }
        };

        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                maxHeight: '70vh',
                overflowY: 'auto',
                width: "100%"
            }} justifyContent={"center"}>
                <Bar data={this.state.data} options={options}/>
            </Box>
        );
    }
}

class PieChart extends ChartsJSChartWrap {
    render() {
        const options = {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'top' as const,
                },
                tooltip: {
                    callbacks: {
                        label: function (context: any) {
                            return `${context.dataset.label}: ${context.raw}`;
                        }
                    }
                }
            }
        };

        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                maxHeight: '70vh',
                width: "100%",
                overflowY: 'auto'
            }} justifyContent={"center"}>
                <Pie data={this.state.data} options={options}/>
            </Box>
        );
    }
}


export type TChartType = "BAR" | "LINE" | "PIE"
export type THideEmptyData = "HIDE" | "SHOW"

interface IChartType {
    renderer: (data: { data: IChartDataRow[], hideEmptyData: THideEmptyData }) => React.ReactElement,
    mainVariables: TColumnTypes[],
    secondaryVariables?: TColumnTypes[],
    icon: React.ReactElement,
    title: string
}

export const CHARTS: { [type in TChartType]: IChartType } = {
    "BAR": {
        renderer: ({data, hideEmptyData}) => <BarChart data={data} hideEmptyData={hideEmptyData}/>,
        mainVariables: ["DATE", "SELECT", "NUMBER"],
        secondaryVariables: ["SELECT", "NUMBER"],
        icon: <BarChartIcon/>,
        title: "Bar Chart"
    },
    "LINE": {
        renderer: ({data, hideEmptyData}) => <LineChart data={data} hideEmptyData={hideEmptyData}/>,
        mainVariables: ["DATE", "NUMBER"],
        secondaryVariables: ["SELECT", "NUMBER"],
        icon: <LineChartIcon/>,
        title: "Line Chart"
    },
    "PIE": {
        renderer: ({data, hideEmptyData}) => <PieChart data={data} hideEmptyData={hideEmptyData}/>,
        mainVariables: ["SELECT", "NUMBER"],
        icon: <PieChartIcon/>,
        title: "Bar Chart"
    },
}