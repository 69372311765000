import {
    DataFieldType,
    E_ENTITIES,
    IDataField,
    IDataFieldSelect,
    IEntityManagerConfig
} from "../../services/types/entities";
import {
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";

export const ENTITIES: {[key in E_ENTITIES]: IEntityManagerConfig} = {
    EXCLUSION_KEYWORDS: {
        title: "Exclusion Keywords",
        endpoint: "keywords/exclusion",
        fields: [
            {title: "keyword", key: "keyword", type: "str"},
            {title: "stemmed", key: "stemmed", type: "str", disabled: true},
            {title: "exact", key: "exact", type: "bool"},
            {title: "regex", key: "regex", type: "bool"},
        ],
        defaultNewItem: {
            keyword: "",
            stemmed: "",
            exact: false,
            regex: false
        },
        accordionHeader: (r) => {return <span>{r.keyword || "new keyword"}</span>}
    },
    INCLUSION_KEYWORDS: {
        title: "Inclusion Keywords",
        endpoint: "keywords/inclusion",
        fields: [
            {title: "keyword", key: "keyword", type: "str"},
            {title: "stemmed", key: "stemmed", type: "str", disabled: true},
            {title: "exact", key: "exact", type: "bool"},
            {title: "regex", key: "regex", type: "bool"},
        ],
        defaultNewItem: {
            keyword: "",
            stemmed: "",
            exact: false,
            regex: false
        },
        accordionHeader: (r) => {return <span>{r.keyword || "new keyword"}</span>}
    },
    NULL_DATA_FLAGS: {
        title: "Empty Data Indicators",
        endpoint: "null_data_flags",
        fields: [
            {title: "flag", key: "flag", type: "str"}
        ],
        defaultNewItem: {
            flag: ""
        }
    },
    SOURCES: {
        title: "Sources",
        endpoint: "sources",
        fields: [
            {title: "Source Name", key: "title", type: "str"},
            {title: "URL", key: "url", type: "str"},
            {
                title: "Source Type", key: "source_type", type: "select",
                options: [{value: "news_site", label: "חדשות"}, {value: "telegram", label: "טלגרם"}]
            },
            {title: "Enabled", key: "is_active", type: "bool"},
            {title: "Requires JS", key: "load_in_browser", type: "bool", hideWhen: (i)=>(i.source_type !== "news_site")},
            {title: "Article Entry Selector (optional)", key: "article_entry_selector", type: "str", hideWhen: (i)=>(i.source_type !== "news_site")},
            {title: "Title Selector (optional)", key: "title_selector", type: "str", hideWhen: (i)=>(i.source_type !== "news_site")},
            {title: "Date Selector (optional)", key: "date_selector", type: "str", hideWhen: (i)=>(i.source_type !== "news_site")},
            {title: "Body Selector (optional)", key: "body_selector", type: "str", hideWhen: (i)=>(i.source_type !== "news_site")},
            {title: "Date Added", key: "date_added", type: "str", disabled: true},
        ],
        defaultNewItem: {
            title: "",
            url: "",
            source_type: "news_site",
            is_active: true,
            load_in_browser: false,
            article_entry_selector: null,
            title_selector: null,
            date_selector: null,
            body_selector: null,
        },
        sx: (r) => ({
            backgroundColor: r.is_active ? "initial" : "#ccc"
        }),
        accordionHeader: (r) => {
            return <Stack direction={"row"} alignItems={"center"}>
                {
                    r.source_type !== "news_site" ?
                    <span> {r.title ? `Telegram Channel (${r.url}) - ${r.title}` : "new telegram"}</span> :
                    <a href={r.url}>{r.title || "new source"}</a>
                }
                {
                    r.id ?
                        <a href={"/source-details/?id=" + r.id}
                           onClick={(e) => {
                               e.stopPropagation()
                           }}
                        >
                            <IconButton color={"primary"}>
                                <SettingsIcon/>
                            </IconButton>
                        </a> : null
                }
            </Stack>
        }
    }
}
export const DataFieldRenderer: { [key in DataFieldType]: (f: IDataField, value: any, onChange: (value: any) => any) => React.ReactElement } = {
    "str": (f: IDataField, value, onChange) => <TextField
        disabled={!!f.disabled}
        label={f.title}
        key={f.key}
        value={value || ""}
        variant="outlined"
        placeholder={f.title}
        onChange={(e) => {
            onChange(e.target.value);
        }}
    />,
    "number": (f: IDataField, value, onChange) => <TextField
        disabled={!!f.disabled}
        label={f.title}
        key={f.key}
        value={value || ""}
        variant="outlined"
        placeholder={f.title}
        onChange={(e) => {
            onChange(e.target.value);
        }}
    />,
    "bool": (f: IDataField, value, onChange) =>
        <FormControlLabel key={f.key} control={
            <Switch
                disabled={!!f.disabled}
                checked={!!value}
                onChange={(e) => {
                    onChange(e.target.checked);
                }}
            />
        } label={f.title}/>,
    "select": (f: IDataField, value, onChange) => {
        return <FormControl
            key={f.key}
        >
            <InputLabel
            >
                {f.title}
            </InputLabel>
            <Select
                label={f.title}
                variant="outlined"
                value={value}
                className={"fill-width-util"}
                disabled={!!f.disabled}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
            >
                {
                    (f as IDataFieldSelect).options.map((o, i: number) => {
                        return <MenuItem value={o.value} key={"option_" + i}>{o.label}</MenuItem>
                    })}
            </Select>
        </FormControl>
    }
}