import React, {Component} from 'react';
import {useNavigate} from 'react-router-dom';
import {Line} from 'react-chartjs-2';
import server from '../services/server';
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid,
} from '@mui/material';
import IncidentCountCard from '../UIComponents/IncidentCountCard/IncidentCountCard';
import AlignDir from "../services/languages/AlignDir";


interface ISystemSnapshot {
  "approved": number,
  "not approved": number,
  "removed": number,
  "total": number
}

interface IState {
    current_stats: ISystemSnapshot | null,
    trendline: ISystemSnapshot[] | null
}

interface IProps {

}

class IncidentsStatus extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            current_stats: null,
            trendline: null,
        };
    }

    async componentDidMount() {
        await this.fetchTrendlineData();
    }

    fetchTrendlineData = async () => {
        const response = await server.get('insights/snapshots/');
        if (response) {
            this.setState({current_stats: response.current_stats})
            return
            // Sort the data by date
            const sortedData = response.sort((a: any, b: any) => new Date(a.x_value).getTime() - new Date(b.x_value).getTime());


            // Log x_values to inspect them
            //console.log('x_values:', sortedData.map((item: any) => item.x_value));

            // Format the x-axis labels
            const labels = sortedData.map((item: any) => {
                const [year, month] = item.x_value.split('-');
                if (!year || !month) {
                    return 'Invalid date';
                }
                const date = new Date(Number(year), Number(month) - 1);
                return date.toLocaleString('default', {
                    month: 'short',
                    year: 'numeric',
                });
            });

            const data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Total Incidents',
                        data: sortedData.map((item: any) => item.incidents_data),
                        fill: false,
                        backgroundColor: 'rgba(75,192,192,0.4)',
                        borderColor: 'rgba(75,192,192,1)',
                        tension: 0.4,
                    },
                    {
                        label: 'Approved Incidents',
                        data: sortedData.map((item: any) => item.approved_incidents_data),
                        fill: false,
                        backgroundColor: 'rgba(54, 162, 235, 0.4)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        tension: 0.4,
                    },
                    {
                        label: 'Not Approved Incidents',
                        data: sortedData.map((item: any) => item.not_approved_incidents_data),
                        fill: false,
                        backgroundColor: 'rgba(255, 206, 86, 0.4)',
                        borderColor: 'rgba(255, 206, 86, 1)',
                        tension: 0.4,
                    },
                    {
                        label: 'Removed Incidents',
                        data: sortedData.map((item: any) => item.removed_incidents_data),
                        fill: false,
                        backgroundColor: 'rgba(255, 99, 132, 0.4)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        tension: 0.4,
                    },
                ],
            };
        }
    };

    render() {
        const {
            current_stats
        } = this.state;

        return (
            <div className={"page-wrap"}>
                <TopNavBar>
                    <div className="title-wrap">
                        Incidents Status
                    </div>
                </TopNavBar>
                <div className={"page-content content-wrap"}>
                    <AlignDir direction={"ltr"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <IncidentCountCard
                                    incidentType="Total Incidents"
                                    count={current_stats?.total || 0}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <IncidentCountCard
                                    incidentType="Approved Incidents"
                                    count={current_stats?.approved || 0}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <IncidentCountCard
                                    incidentType="Not Approved Incidents"
                                    count={0}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <IncidentCountCard
                                    incidentType="Removed Incidents"
                                    count={current_stats?.removed || 0}
                                />
                            </Grid>
                        </Grid>
                        <Card sx={{marginTop: 3, width: '100%'}}>
                            <CardContent>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant="h5" component="h2">
                                        Incident Trendline
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                        Click on the color tabs to choose the status of your chart
                                    </Typography>
                                </Box>
                                <Box sx={{width: '100%', height: 600}}>
                                    {/**<Line
                                        data={[]}
                                        options={{
                                            maintainAspectRatio: false,
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                        text: 'Month',
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                        text: 'Number of Incidents',
                                                    },
                                                },
                                            },
                                        }}
                                    />**/}
                                </Box>
                            </CardContent>
                        </Card>
                    </AlignDir>
                </div>
            </div>
        );
    }
}

const IncidentsStatusWithNavigate = (props: any) => {
    const navigate = useNavigate();
    return <IncidentsStatus {...props} navigate={navigate}/>;
};

export default IncidentsStatusWithNavigate;
